.mapboxgl-ctrl-geocoder {
  @apply max-w-none flex-grow flex items-center shadow rounded-md hover:border-indigo-800 !important
}

.mapboxgl-ctrl-geocoder--input {
  @apply rounded h-full text-base text-gray-600 focus:border-indigo-800 focus:ring focus:ring-indigo-800 focus:ring-opacity-25 focus:outline-none !important
}

.mapboxgl-ctrl-geocoder--input::placeholder {
  @apply text-gray-600
}

.mapboxgl-ctrl-geocoder--icon, .mapboxgl-ctrl-geocoder--button {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  @apply left-2 !important
}

.mapboxgl-ctrl-geocoder--icon-close {
  @apply m-0 !important
}

.mapboxgl-ctrl-geocoder--button {
  top: 25% !important;
  right: 10px !important;
}

#address .mapboxgl-ctrl-geocoder {
  @apply w-full min-h-10;
}
